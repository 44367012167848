/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './styles.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import WalletImg from "../../../assets/images/Wallet-bro.png"
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
// axios

import axios from "axios";
// firebase 
import { useSelector } from 'react-redux';
import { app } from '../../../firebaseConfig';
import { async } from "@firebase/util";
import Progress from "components/MDProgress"
import Modal from "../../../components/MDNotification"
import Image from "assets/images/success.png";
import Help from "layouts/authentication/components/help"
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic({setIndex, submitted, setSubmitted }) {
  const [rememberMe, setRememberMe] = useState('');
  const maxTrialTickets = useSelector(state => state.maxtrialtickets);
  const nbrOfDays = useSelector(state => state.nbrofdays);
  const montant = useSelector(state => state.montant);
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const user = useSelector(state => state.user);
  const [texte, setTexte] = useState("")
  const [color, setColor] = useState('#000000');
  const [error, setError] = useState(false);
  const [gender, setGender] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [openNotif, setOpenNotif] = useState(false)
  const [message, setMessage] = useState("")
  const [className, setClassName] = useState("modalContainer")
  const [disableButton, setDisableButton] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedAssurance, setIsCheckedAssurance] = useState(false);
  const [codePayment, setCodePayment] = useState(`<!DOCTYPE html>
  <html>
    <head>
      <title></title>
    </head>
    <body>
      <h1></h1>
      <p></p>

    </body>
  </html>`)
  const handleGenderSelect = (event) =>{
     event.preventDefault()
     var element = document.getElementById(event.target.id);
     var computedStyles = window.getComputedStyle(element);
     var borderColor = computedStyles.borderColor;
     var borderColorRgb = borderColor.match(/\d+/g).map(function(value) {
       return parseInt(value);
     });
 
     if (borderColorRgb[0] === 38 && borderColorRgb[1] === 217 && borderColorRgb[2] === 140) {
       document.getElementById(event.target.id).style.borderColor = "#EFF4F2"

       setGender("")
       setDisableButton(false)
     }else {
      document.getElementById(event.target.id).style.borderColor = "#26D98C";
      setDisableButton(true)
      if (event.target.id == "femme") {
        setGender("female")
        document.getElementById("homme").style.borderColor = "#EFF4F2"
      }else{
        setGender("male")
        document.getElementById("femme").style.borderColor = "#EFF4F2"
      }
     }
  }
  const navigatePages = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {
            
                if (res.data?.gym?.completedAt) {
          
                    setIndex(11)
                    
                  
                  
                }else{
                  setIndex(9)
                }
                
              
              
            
           }
      
    })
  }
  const updateUser = async()=>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/athletes/users/${user.email}`)
    .then(async(res) => {
      const g = res.data
      await axios
    .patch(`${process.env.REACT_APP_API_DEV}/athletes/${user._id}`,
    {
      email: user.email,
      gender: gender,
    },).then(async(res)=>{

      setError(false)
     await navigatePages()
    })
    })
  }

  const getFreeTrialStartDate= async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {

        setStartDate(new Date(res.data.startAt.res).toLocaleDateString('en-GB'))
        setSubmitted(false)
      }
    })
  }
  const updateFreeTrialCheckout= async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {

        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          checkout: {
            amount: montant,
            completedAt: new Date()
          }
        }).then(async()=>{
          await sendPaymentForm()
        })
      }
    })
  }

const sendPaymentForm = async() =>{
  await axios
  .get(`${process.env.REACT_APP_API_DEV}/orders/trial-pack?packid=${process.env.REACT_APP_PackId}&userId=${user._id}`)
  .then((res)=>{

      setCodePayment(String(res.data))
      setSuccess(true)
  })
}
const onsubmit = async() =>{
  
 await updateFreeTrialCheckout()
}
const containerRef = useRef(null);
useEffect(async() => {
  if (submitted) {
    await getFreeTrialStartDate()

  }
  
}, [submitted])
  useEffect(() => {
    if (success) {
        const scriptNodes = containerRef.current.getElementsByTagName('script');
        for (let i = 0; i < scriptNodes.length; i++) {
          const node = scriptNodes[i];
          const script = document.createElement('script');
          script.innerHTML = node.innerHTML;
          node.parentNode.replaceChild(script, node);
        }
    }

  }, [success]);
  useEffect(() => {   

  }, []);


  return (
    <>
    {!success && 
        <PageLayout>
        <MDBox
          px={1}
          width='100%'
          bgColor='white'
          height='100vh'
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) => image && `url(${image})`,
            backgroundSize: '120% !important',
            backgroundPosition: '45% -250px !important',
            backgroundRepeat: 'no-repeat',
          }}
          mx='auto'
        >
          <Navbar />
          
          <Grid
            container
            spacing={1}
            justifyContent='center'
            alignItems='center'
          >
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3} marginTop={-1}>
            <Progress color='purple' value={100}/>
            <Help message={`Bonjour, mon mail est ${user?.email}. Je suis sur le formulaire de la période d'essai et  `}/>
            <MDBox textAlign="center">
            <Typography color={color} variant='h4' marginTop={0} justifyContent='center' fontWeight='bold'>
              Confirmez votre achat
              </Typography>
            </MDBox>
            <MDBox marginTop={-4} textAlign="center">
              <div className="iconCheck">
                <img className="checkoutIcon" src={WalletImg} alt="image description" />
              </div>
            </MDBox>
            <Box>

                <MDBox
                  //coloredShadow='dark'
                  //pt={4}
                  //pb={3}
                  //px={3}
                    marginTop={0}
                  >
                  <MDBox textAlign="center"
                  >
                    <p className={`textbottom ${error ? 'error' : ''} `}>{texte}</p>
                  </MDBox>
  
                  <MDBox marginTop={0} component='form' role='form'>
                  
                  <p className="termsText">Profitez de <span className="textbold">{maxTrialTickets} cours </span>de sport sur <span className="textbold">{nbrOfDays} jours</span>  à partir de la date de début.
                </p>
          
                <p className="termsText">Date de début souhaitée: <span className="textbold">{startDate}</span> 
                </p>
            
                <p className="termsText">Montant: <span className="textbold">{montant} dhs</span> 
                </p>
                <MDBox marginTop={1}>
                <div className="container-check"  style={{ display: 'flex'}}>
                    <FormControlLabel 
                    style={{ display: 'inline-block' }}
                    control={<Checkbox onChange={e => {setIsCheckedAssurance(e.target.checked)}}/>} 
                    />
                    <p className='terms' style={{ display: 'inline-block' }}>
                            <span class="accept">Les frais d’assurance ne sont pas inclus dans la période d’essai. J’engage donc ma propre responsabilité en cas d’accident.</span>
                          
                            

                            </p>
                    </div>
                   
                    
                    <div className="container-check"  style={{ display: 'flex'}}>
                    <FormControlLabel 
                    style={{ display: 'inline-block' }}
                    control={<Checkbox onChange={e => {setIsChecked(e.target.checked)}}/>} 
                    />
                    <p className='terms' style={{ display: 'inline-block' }}>
                            <span class="accept">J'accèpte </span>
                            <span class="link"><a href="https://yofitt.com/conditions-generales-de-vente/" target="_blank">les conditions générales de ventes</a></span>
                            

                            </p>
                    </div>
                   
                  
                  </MDBox>
                    <MDBox marginTop={3} textAlign='center'>
                    <MDButton
                     onClick={()=>{setIndex(4)}}
                     fullWidth
                   //  sx={{width:190}}
                     variant="outlined"
                     color='warning'
                    
                   >
                     MODIFIER LA DATE DE DÉBUT
                   </MDButton>
                   <MDBox marginTop={2} textAlign='center'>
                   <MDButton
                     onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                     color= {(isChecked && isCheckedAssurance) ? 'purple' : 'light'}
                     disabled={(isChecked && isCheckedAssurance) ? false : true}
                   >
                     Payer
                   </MDButton>
                   </MDBox>
                  
                    
                    </MDBox>
                 
                  </MDBox>
                </MDBox>
              </Box>
            </Grid>
          </Grid>
        </MDBox>
        {openNotif && <Modal setOpenModal={setOpenNotif} img={Image} msg={message} className={className}/>}
      </PageLayout>
    }
    {success && 
    <div ref={containerRef} dangerouslySetInnerHTML={{ __html:  codePayment  }} />
    }
    </>
    
   

  );
}

export default Basic;
