
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  /** 
  apiKey: 'AIzaSyDs0XK36adtXvUZlKCP88iRpEbWajpN2cI',
  authDomain: 'yofitt-staging.firebaseapp.com',
  projectId: 'yofitt-staging',
  storageBucket: 'yofitt-staging.appspot.com',
  messagingSenderId: '682690640655',
  appId: '1:682690640655:web:4c42d7f39e5324ce331c7a',
  measurementId: 'G-TGHCP8NC70'
  */

  apiKey: 'AIzaSyCmjHZ_y01YWZG64iFKSzxwN55gP3gEpCU',
  authDomain: 'yofitt-2e712.firebaseapp.com',
  projectId: 'yofitt-2e712',
  storageBucket: 'yofitt-2e712.appspot.com',
  messagingSenderId: '95215354155',
  appId: '1:95215354155:web:959079c50e1fa52a57856e',
  measurementId: 'G-B1GC2H2PD5'


};

export const app = initializeApp(firebaseConfig);


